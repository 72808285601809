import PropTypes from "prop-types";
import React from "react";
import {FaArrowLeft, FaArrowRight, FaFilePdf} from "react-icons/fa";

const icons = {
  FaArrowLeft,
  FaArrowRight,
  FaFilePdf
}

const getIcon = (iconId) => {
  return icons[iconId];
}

const Icon = (props) => {
  const IconComponent = getIcon(props.name);
  return (
     IconComponent ?
      (<div className={`${props.showBrush ? 'brush brush-center brush-contain' : ''}`} style={{padding: '10px', margin: '-10px'}}>
        <IconComponent/>
      </div>) :
       null
  );
}

Icon.propTypes = {
  name: PropTypes.string,
  showBrush: PropTypes.bool
}

export default Icon

