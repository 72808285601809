import PropTypes from "prop-types";
import React from "react";
import {Link} from "gatsby"
import Icon from "./icon";

const IconLink = ({to, icon, left, children, size, renderAnchor}) => {
  return renderAnchor ?
      <Link to={to} className={`icon-link icon-link-${size}`}>
        <div className={`d-flex flex-row${left ? '-reverse' : ''} align-content-center`}>
          <div className='icon-link-text' style={{fontWeight: 500, textTransform: 'uppercase'}}>
            {children}
          </div>
          <div className={`${left ? 'mr-1' : 'ml-1'} icon-link-icon${left ? '-left' : '-right'}`}>
            <Icon name={icon}/>
          </div>
        </div>
      </Link> :
      <div className={`icon-link icon-link-${size}`}>
        <div className={`d-flex flex-row${left ? '-reverse' : ''} align-content-center`}>
          <div className='icon-link-text' style={{fontWeight: 500, textTransform: 'uppercase'}}>
            {children}
          </div>
          <div className={`${left ? 'mr-1' : 'ml-1'} icon-link-icon${left ? '-left' : '-right'}`}>
            <Icon name={icon}/>
          </div>
        </div>
      </div>
}

IconLink.propTypes = {
  icon: PropTypes.string,
  to: PropTypes.string.isRequired,
  left: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  renderAnchor: PropTypes.bool
}

IconLink.defaultProps = {
  to: '/',
  left: false,
  size: 'lg',
  renderAnchor: true
}

export default IconLink
