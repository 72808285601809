import React from "react";
import {graphql} from 'gatsby'
import Layout from "../components/layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { RichText } from 'prismic-reactjs';
import {Image} from "react-bootstrap";
import Email from "../components/email";
import Phone from "../components/phone";
import IconLink from "../components/icon-link";
import GatsbyLink from "../components/gatsby-link";

const TeamDetailPage = ({ data, pageContext }) => {
  const person = data.prismicTeamDetail;
  const team = data.prismicTeam;
  return (
    <Layout title={RichText.asText(person.data.name.raw)}>
      <section>
        <Row className="mb-4">
          <Col md={3}>
          </Col>
          <Col md={9}>
            <IconLink to={team.url} icon="FaArrowLeft" left size="sm">
              {RichText.asText(team.data.titel.raw)}
            </IconLink>
          </Col>
        </Row>
        <Row>
          <Col md={3} className="mb-4 mb-md-0">
            <Image className="img-avatar" src={person.data.portrait.fixed.src} alt={RichText.asText(person.data.name.raw)} width={200} height={200}/>
          </Col>
          <Col md={9}>
            <p className="spitzname">{person.data.spitzname}</p>
            <h1>{RichText.asText(person.data.name.raw)}</h1>
            <RichText render={person.data.lebenslauf.raw} serializeHyperlink={GatsbyLink}/>
            <div className="d-flex flex-column mt-5">
              <Phone phone={person.data.phone}/>
              <Email email={person.data.email}/>
            </div>
          </Col>
        </Row>
      </section>
      <section className="mb-5">
        <Row>
          <Col md={3}>
          </Col>
          <Col md={9} className="d-flex flex-column justify-content-center align-items-center flex-sm-row justify-content-sm-between">
            <IconLink to={pageContext.prev.url} icon="FaArrowLeft" left>
              <span style={{textTransform: "none"}}>{RichText.asText(pageContext.prev.data.name.raw)}</span>
            </IconLink>
            <IconLink to={pageContext.next.url} icon="FaArrowRight">
              <span style={{textTransform: "none"}}>{RichText.asText(pageContext.next.data.name.raw)}</span>
            </IconLink>
          </Col>
        </Row>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($uid: String!) {
    prismicTeam {
      url
      data {
        titel {
          raw
        }
      }
      
    }
    prismicTeamDetail(uid: { eq: $uid }) {
      uid
      id
      data {
        email
        lebenslauf {
          raw
        }
        name {
          raw
        }
        phone
        portrait {
          fixed {
            src
          }
        }
        spitzname
      }
    }
  }
`

export default TeamDetailPage
